<template>
	<div class="w-full relative">
		<div class="absolute h-full flex justify-center items-center">
			<img class="h-[50%] sm:h-full cover-image sm:default-image" src="../../assets/svg/wemotoo-title.svg" />
		</div>

		<div class="absolute h-full w-full flex flex-col justify-evenly items-center">
			<h1 class="text-white font-bold text-2xl md:text-3xl font-chaos-madness-regular">Brand Partnership</h1>

			<carousel class="w-full" :autoplay="2000" :items-to-show="2.3" :wrap-around="true" :breakpoints="breakpoints">
				<slide v-for="item in items" :key="item">
					<NuxtImg class="w-[80px] h-[80px] md:w-[100px] md:h-[100px] rounded-md" :src="item" />
				</slide>
			</carousel>

			<NuxtLink :to="{ hash: '#eventSection' }" :external="true">
				<UButton class="button px-7 py-2" variant="ghost" size="xl" :ui="{ rounded: 'rounded-none' }">
					<div class="flex items-center">
						<p class="text-wemotooPrimary font-america text-xl sm:text-2xl mt-1">Purchase Ticket</p>
						<img class="ml-3 w-[12px] h-[12px]" src="../../assets/svg/chevron-double-down.svg" />
					</div>
				</UButton>
			</NuxtLink>
		</div>

		<div class="container">
			<img
				class="w-full aspect-[2] min-h-[350px] max-h-[400px] sm:aspect-[3] sm:min-h-[400px] sm:max-h-[420px] cover-image sm:default-image"
				src="../../assets/background/partnership.png"
				alt="background_partnership"
			/>
		</div>
	</div>
</template>

<script lang="ts" setup>
const items = [
	'https://www.wemotoo.com/img/partnership/alatin.jpg',
	'https://www.wemotoo.com/img/partnership/ceria-rider.png',
	'https://www.wemotoo.com/img/partnership/eco-venture-village.jpeg',
	'https://www.wemotoo.com/img/partnership/furiousonce.jpeg',
	'https://www.wemotoo.com/img/partnership/kawasaki.jpg',
	'https://www.wemotoo.com/img/partnership/musc.jpeg',
	'https://www.wemotoo.com/img/partnership/rievo.jpeg',
	'https://www.wemotoo.com/img/partnership/sabah.jpg',
];

const breakpoints = {
	0: {
		itemsToShow: 4,
		snapAlign: 'center',
	},
	600: {
		itemsToShow: 5,
		snapAlign: 'center',
	},
	768: {
		itemsToShow: 6.5,
		snapAlign: 'center',
	},
};
</script>

<style scoped>
.container {
	max-width: 100%;
}

.fit-height-image {
	object-fit: fill;
}
.button {
	background-image: url('/assets/background/button.png');
}
</style>
