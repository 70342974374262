<template>
	<div class="items-center justify-center pb-12 px-4 sm:px-20">
		<div class="flex justify-center">
			<ClientOnly>
				<LazyTextCurve class="text-wemotooBlue-350 text-lg sm:text-xl font-chaos-madness-regular mt-6 sm:mt-20" :width="280" :height="80" :r="60" offset="50%">
					· MARK YOUR CALENDAR ·
				</LazyTextCurve>
			</ClientOnly>
		</div>
		<div class="flex flex-col items-center sm:flex-row sm:justify-center font-america text-3xl md:text-5xl text-wemotooBlue sm:mt-6">
			<div class="text-center">UPCOMING RACING EVENTS IN</div>
			<div class="flex items-center font-america text-3xl md:text-5xl cursor-pointer" @click="isStateModelOpen = true">
				<TextBrush :text="eventStore.getStateName" image-classes="bottom-3" />
				<UIcon class="text-wemotooPrimary mb-2" name="i-heroicons-chevron-down-16-solid" />
			</div>
		</div>

		<div class="grid md:grid-cols-3 sm:grid-cols-2 gap-4 place-items-stretch px-0 sm:px-4">
			<div v-for="(event, index) in eventsByState.slice(0, 6)" :key="index">
				<EventCard :event="event" />
			</div>
		</div>

		<div class="w-full flex justify-center">
			<UButton v-if="showMoreEventsButton" variant="ghost" class="mt-12 mb-4" @click="onClickShowMore()">
				<div class="flex justify-center items-center">
					<p class="text-wemotooPrimary font-america text-2xl mt-1 mx-1">SHOW MORE EVENTS</p>
					<UIcon class="text-wemotooPrimary text-2xl mb-1" name="i-heroicons-chevron-down-16-solid" />
				</div>
			</UButton>
		</div>

		<LazyUModal v-model="isStateModelOpen" @close="onClose">
			<UCard
				:ui="{
					padding: 'p-0 sm:p-0',
					inner: 'overflow-y-hidden',
					base: 'min-h-[500px] max-h-[500px] overflow-y-auto no-scrollbar',
					ring: '',
					rounded: '',
					divide: 'divide-y divide-gray-100 dark:divide-gray-800',
					footer: {
						padding: 'py-0 px-0 sm:px-0',
					},
					header: {
						padding: 'py-0 px-0 sm:px-0',
					},
				}"
			>
				<template #header>
					<div class="flex flex-col">
						<div class="flex justify-between items-center p-4">
							<h4 class="font-jakarta text-base text-wemotooGrey sm:py-2">Select State</h4>
							<UIcon class="font-jakarta text-base text-wemotooGrey w-[20px] h-[20px]" name="i-heroicons-x-mark-solid" @click="isStateModelOpen = false" />
						</div>

						<div class="w-full">
							<InputBorderlessForm label-props="Search State" name-props="keyword" :value-props="keyword" @_input="searchState" />
						</div>
					</div>
				</template>

				<template #footer>
					<div>
						<h4
							:class="[stateId == null ? 'bg-wemotooPrimary text-white ' : 'bg-white text-wemotooGrey ']"
							class="cursor-pointer font-jakarta text-base p-4 hover:bg-wemotooPrimary-50 hover:text-wemotooBlue"
							@click="updateState(null)"
						>
							All States
						</h4>
						<h4
							v-for="state in states"
							:key="state.id"
							:class="[stateId == state.id ? 'bg-wemotooPrimary text-white ' : 'bg-white text-wemotooGrey ']"
							class="cursor-pointer font-jakarta text-base p-4 border-t hover:bg-wemotooPrimary-50 hover:text-wemotooBlue"
							@click="updateState(state.id)"
						>
							{{ state.name }}
						</h4>
					</div>
				</template>
			</UCard>
		</LazyUModal>
	</div>
</template>

<script lang="ts" setup>
import type { State } from '../../types';

const eventStore = useEventStore();
const utilsState = useUtilsDataStore();

const keyword = ref('');

const isStateModelOpen = ref(false);

const { states } = storeToRefs(utilsState);
const { eventsByState, stateId, showMoreEventsButton } = storeToRefs(eventStore);

const statesCopy = ref<State[]>([...states.value]);
const searchState = (input: string) => {
	if (!input) {
		states.value = [...statesCopy.value];
		return;
	}
	const value = input!.toLowerCase();
	states.value = statesCopy.value.filter((state: State) => {
		return state.name.toLowerCase().includes(value);
	});
};

const onClose = () => {
	states.value = [...statesCopy.value];
};
const onClickShowMore = async () => {
	await eventStore.getEventByStateId(eventsByState.value.length);
	navigateTo(`/events`);
};

const updateState = async (id: number | null) => {
	await eventStore.updateStateId(id == null ? undefined : id);
	isStateModelOpen.value = false;
};
</script>

<style scoped></style>
