<template>
	<div class="w-full flex flex-col">
		<div class="relative z-0">
			<img format="webp" class="w-full h-full min-h-[650px] md:min-h-[450px] z-0 cover-image" src="../../assets/background/bg-download-app.jpg" />
			<div class="absolute top-0 left-0 right-0 bottom-0 px-4 sm:px-20 pt-6 md:pt-0">
				<div class="w-full flex flex-col md:flex-row h-[100%] justify-between">
					<div class="flex flex-col justify-center items-center md:items-start pt-2 md:pt-0">
						<h1 class="text-white font-america text-[3rem] md:text-[5rem]">
							DO MORE WITH <span class="text-wemotooPrimary block sm:inline text-center">OUR APP</span>
						</h1>
						<div class="w-full flex justify-evenly md:justify-start gap-5 sm:gap-12">
							<div class="flex flex-col justify-center items-center min-w-[80px] max-w-[80px]">
								<img src="../../assets/svg/ticket.svg" />
								<h6 class="text-white font-jakarta text-center mt-3 text-sm md:text-lg">Book <span class="block">Ticket</span></h6>
							</div>
							<div class="flex flex-col justify-center items-center min-w-[80px] max-w-[80px]">
								<img src="../../assets/svg/meter.svg" />
								<h6 class="text-white font-jakarta text-center mt-3 text-sm md:text-lg">Track Performance</h6>
							</div>
							<div class="flex flex-col justify-center items-center min-w-[80px] max-w-[80px]">
								<img src="../../assets/svg/chat.svg" />
								<h6 class="text-white font-jakarta text-center mt-3 text-sm md:text-lg">Join Community</h6>
							</div>
						</div>
					</div>
					<div class="justify-end md:flex z-0 mx-auto sm:mx-0 mt-5 sm:mt-20">
						<img class="pt-4 md:pt-5" src="../../assets/background/sample-app.png" />
					</div>
				</div>
			</div>
		</div>
		<div class="bg-wemotooBlack z-50 gradient-border">
			<div class="py-6 sm:py-4 z-50 px-4 sm:px-20">
				<div class="flex flex-col sm:flex-row justify-center sm:justify-start items-center gap-4">
					<h1 class="font-jakarta text-white text-[14px] font-semibold">Get the app from :</h1>
					<div class="flex sm:mt-0 gap-3">
						<NuxtLink to="https://play.google.com/store/apps/details?id=com.etc.wemotoo&pli=1" target="_blank" @click="logEvent(DOWNLOAD_GOOGLE_PLAY)">
							<img src="../../assets/img/download-from-googleplay.png" sizes="1vw" />
						</NuxtLink>

						<NuxtLink to="https://apps.apple.com/us/app/id1612233648" target="_blank" @click="logEvent(DOWNLOAD_IOS_APPSTORE)">
							<img src="../../assets/img/download-from-appstore.png" sizes="1vw" />
						</NuxtLink>

						<NuxtLink to="https://appgallery.huawei.com/app/C106211609" target="_blank" @click="logEvent(DOWNLOAD_HUAWEI_APP_GALLERY)">
							<img src="../../assets/img/download-from-huawei.png" sizes="1vw" />
						</NuxtLink>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
import { DOWNLOAD_GOOGLE_PLAY, DOWNLOAD_HUAWEI_APP_GALLERY, DOWNLOAD_IOS_APPSTORE } from '../../utils/analytic-events-const';

async function logEvent(eventName: string) {
	try {
		const firebase = useFirebaseStore();
		firebase.logCustomEvent(eventName);
	} catch (error) {
		console.error('Error logging event:', error);
	}
}
</script>

<style scoped>
.gradient-border {
	border-image: linear-gradient(to right, #f37323, #f7a470, #1c4771, #0c1e30);
	border-image-slice: 1;
	border-bottom: 1px solid;
}
</style>
