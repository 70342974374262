<template>
	<div class="flex-auto background pt-16 pb-32 md:pl-20">
		<!-- direction to the right -->
		<div class="w-full hidden md:flex flex-row mt-24">
			<!-- left -->
			<!-- <div class="flex max-w-540"> -->
			<div class="w-[45%] flex flex-col">
				<div class="overflow-hidden">
					<Transition name="fade" mode="out-in">
						<NuxtImg :key="currentBackgroundIndex" :src="items[currentBackgroundIndex].src" class="w-full aspect-[4/3] cover-image" />
					</Transition>
				</div>

				<div class="banner mt-8 w-full" />
			</div>
			<!-- </div> -->
			<!-- right -->
			<div class="ml-10 justify-start relative pt-2 w-[55%]">
				<div>
					<div class="h-1/2 w-full overflow-hidden mt-1">
						<Transition name="fade" mode="out-in">
							<div :key="currentBackgroundIndex">
								<TextDirt
									:key="currentBackgroundIndex"
									:text="items[currentBackgroundIndex].alt"
									text-classes="text-[80px] whitespace-nowrap lg:whitespace-normal title"
								/>
								<div class="mt-2">
									<ButtonImage
										v-if="currentBackgroundIndex == 0"
										title="Purchase Ticket"
										title-class="text-3xl"
										@click="onClickPurchase(items[currentBackgroundIndex].id)"
									/>
								</div>
							</div>
						</Transition>
					</div>

					<div class="absolute bottom-1 right-0 w-full overflow-hidden">
						<carousel
							ref="carouselRef"
							v-model="carouselIndex"
							class="w-full"
							:wrap-around="true"
							:breakpoints="breakpoints"
							snap-align="start"
							@slide-start="handleIndexChange"
						>
							<slide v-for="item in items" :key="item.src">
								<div class="relative w-[120px] lg:w-[180px] aspect-[4/3]" @click="onClickImage(item)">
									<NuxtImg :src="item.src" class="w-full aspect-[4/3] rounded-[4px] cover-image" />

									<div
										:class="[
											'absolute inset-0 flex justify-center items-center hover:border-4 hover:border-wemotooPrimary hover:bg-black hover:bg-opacity-20 transition-all duration-100',
											currentBackgroundIndex === items.indexOf(item)
												? 'rounded-[2px] border-4 border-wemotooPrimary bg-black bg-opacity-20 transition-all duration-100'
												: 'rounded-[4px] hover:border-4 hover:border-wemotooPrimary hover:bg-black hover:bg-opacity-20 transition-all duration-100',
										]"
										@mouseover="onMouseOver(items.indexOf(item), item)"
										@mouseout="onMouseOut()"
									>
										<ButtonImage
											v-if="items.indexOf(item) != 0"
											:class="[' opacity-0 transition-all duration-100', currentMouseOverIndex === items.indexOf(item) ? 'opacity-100' : 'hover:opacity-100']"
											title="Purchase Ticket"
											title-class="text-xl h-7"
											@click="onClickPurchase(items[items.indexOf(item)].id)"
										/>
									</div>
								</div>
							</slide>
						</carousel>

						<!-- Next / Previous -->
						<div class="absolute left-0 top-1/2 transform -translate-y-1/2 pl-2">
							<div class="relative bg-white p-4 flex justify-center items-center" @click="onPreviousCarousel">
								<UIcon name="i-heroicons-chevron-left" class="w-[16px] h-[16px] text-wemotooPrimary" />
								<div class="absolute inset-0 flex hover:bg-black hover:opacity-20 transition-all duration-100"></div>
							</div>
						</div>
						<div class="absolute right-0 top-1/2 transform -translate-y-1/2 pr-2">
							<div class="relative bg-white p-4 flex justify-center items-center" @click="onNextCarousel">
								<UIcon name="i-heroicons-chevron-right" class="w-[16px] h-[16px] text-wemotooPrimary" />
								<div class="absolute inset-0 flex hover:bg-black hover:opacity-20 transition-all duration-100"></div>
							</div>
						</div>

						<!-- Indicator -->
						<div class="flex justify-center ml-1 mr-10 pt-5">
							<div class="relative w-full">
								<div class="absolute flex bg-white mr-2 h-2 w-full"></div>
								<div
									class="flex bg-wemotooPrimary mr-2 h-2 w-full"
									:style="{
										position: 'relative',
										left: `${(currentBackgroundIndex / items.length) * 100}%`,
										width: `${(1 / items.length) * 100}%`,
										transition: 'left 0.5s ease',
									}"
								></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- MOBILE -->
		<div class="w-full flex flex-col md:hidden mt-24 pl-4">
			<div class="w-full overflow-hidden">
				<carousel
					ref="carouselRef"
					v-model="currentBackgroundIndex"
					class="w-full"
					:autoplay="5000"
					:wrap-around="true"
					:breakpoints="breakpoints"
					snap-align="start"
					@slide-start="handleIndexChange"
				>
					<slide v-for="item in items" :key="item.src">
						<div class="relative w-[370px] aspect-[4/3] mobile-banner" @click="onClickImage(item)">
							<NuxtImg :src="item.src" class="w-[370px] rounded-[4px] aspect-[4/3] mobile-banner cover-image" />

							<div
								:class="[
									'hidden sm:flex absolute inset-0  justify-center items-center hover:border-4 hover:border-wemotooPrimary hover:bg-black hover:bg-opacity-20 transition-all duration-100',
									currentBackgroundIndex === items.indexOf(item)
										? 'rounded-[2px] border-4 border-wemotooPrimary bg-black bg-opacity-20 transition-all duration-100'
										: 'rounded-[4px] hover:border-4 hover:border-wemotooPrimary hover:bg-black hover:bg-opacity-20 transition-all duration-100',
								]"
								@mouseover="onMouseOver(items.indexOf(item), item)"
								@mouseout="onMouseOut()"
							>
								<ButtonImage
									:class="['opacity-0 transition-all duration-100', currentMouseOverIndex === items.indexOf(item) ? 'opacity-100' : 'hover:opacity-100']"
									title="Purchase Ticket"
									title-class="text-xl h-7"
									@click="onClickPurchase(items[items.indexOf(item)].id)"
								/>
							</div>
						</div>
					</slide>
				</carousel>

				<div class="flex justify-center pr-4 pt-5">
					<div class="relative w-full">
						<div class="absolute flex bg-white mr-2 h-1 w-full" />
						<div
							class="flex bg-wemotooPrimary mr-2 h-1 w-full"
							:style="{
								position: 'relative',
								left: `${(currentBackgroundIndex / items.length) * 100}%`,
								width: `${(1 / items.length) * 100}%`,
								transition: 'left 0.5s ease',
							}"
						></div>
					</div>
				</div>
			</div>

			<Transition name="fade" mode="out-in">
				<div :key="currentBackgroundIndex" class="my-4 pr-4">
					<TextDirt :key="currentBackgroundIndex" :text="items[currentBackgroundIndex].alt" text-classes="text-[40px] title" />
					<ButtonImage title="Purchase Ticket" title-class="text-3xl h-10" classes="mt-6" @click="onClickPurchase(items[currentBackgroundIndex].id)" />
				</div>
			</Transition>
		</div>
	</div>
</template>

<script lang="ts" setup>
const carouselRef = ref(null);
const currentBackgroundIndex = ref(0);
const carouselIndex = ref(0);
const currentMouseOverIndex = ref(-1);
const timer = ref(1);
let interval: any;

type CarouselItem = {
	src: string;
	alt: string;
	id: number;
};

const eventStore = useEventStore();
const { events } = storeToRefs(eventStore);

const items: CarouselItem[] = events.value.map((event) => ({
	src: event.thumbnail.path.fs,
	alt: event.name,
	id: event.id,
}));

function onMouseOver(index: number, data: CarouselItem) {
	currentMouseOverIndex.value = index;
	currentBackgroundIndex.value = index;
	onClickImage(data);
	clearInterval(interval);
	interval = null;
	timer.value = 1;
}

function onMouseOut() {
	interval = setInterval(() => {
		if (timer.value === 0) {
			currentMouseOverIndex.value = -1;
			currentBackgroundIndex.value = 0;
			clearInterval(interval);
		} else {
			timer.value--;
		}
	}, 1000);
}

function handleIndexChange(data: any) {
	currentBackgroundIndex.value = data.slidingToIndex % items.length;
}

function onClickImage(data: CarouselItem) {
	currentBackgroundIndex.value = items.findIndex((item) => item.src === data.src);
}
function onNextCarousel() {
	carouselIndex.value = (currentBackgroundIndex.value + 1) % items.length;
}
function onPreviousCarousel() {
	carouselIndex.value = (currentBackgroundIndex.value - 1 + items.length) % items.length;
}

const onClickPurchase = async (id: number) => {
	try {
		const firebase = useFirebaseStore();
		const name = events.value.find((event) => event.id === id)?.name;
		firebase.logCustomEvent(SELECT_ITEM, {
			event_id: id,
			event_name: name,
		});
	} catch (error) {
		console.error('Error logging event:', error);
	}

	navigateTo(`/events/${id}`);
};

const breakpoints = {
	0: {
		itemsToShow: 1.1,
	},
	600: {
		itemsToShow: 3.3,
	},
	768: {
		itemsToShow: 3.8,
	},
	1024: {
		itemsToShow: 3.5,
	},
	1280: {
		itemsToShow: 4.1,
	},
};
</script>

<style scoped>
.background {
	width: 100%;
	background-image: url('../assets/background/hero-background.png');
	background-size: cover;
	background-position: center;
}

.fade-enter-active {
	transition: all 0.3s ease-out;
}

.fade-leave-active {
	transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.fade-enter-from,
.fade-leave-to {
	transform: translateX(20px);
	opacity: 0;
}

.banner {
	width: 100%;
	height: 40px;
	overflow: hidden;
	display: flow-root;
	background-image: url('../assets/img/moving-banner.png');
	background-position: contain center;
	animation: slideshow 3s linear infinite;
}

.title {
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	/* number of lines to show */
	line-clamp: 2;
	-webkit-box-orient: vertical;
}

.mobile-title {
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	/* number of lines to show */
	line-clamp: 3;
	-webkit-box-orient: vertical;
}

@keyframes slideshow {
	0% {
		background-position-x: 0;
	}

	100% {
		background-position-x: -200px;
	}
}

@media (min-width: 360px) and (max-width: 374px) {
	.mobile-banner {
		width: 310px;
	}
}
@media (min-width: 375px) and (max-width: 389px) {
	.mobile-banner {
		width: 320px;
	}
}

@media (min-width: 390px) and (max-width: 409px) {
	.mobile-banner {
		width: 330px;
	}
}

@media (min-width: 410px) and (max-width: 420px) {
	.mobile-banner {
		width: 350px;
	}
}
</style>
